import React, { useContext, useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { SpaceContext } from "@hooks/SpaceContext"
import { toast } from "react-toastify"
import { useAuth } from "@auth/useAuth"
import { SO_ADMIN, SO_USER } from "./_constants"
import OnlyAuthorized from "@auth/OnlyAuthorized"

const UserForm = ({ buttonLabel = "Add", existingUser = {} }) => {
  const { makeRequest } = useAuth() || {}

  const {
    data: { user },
  } = useContext(SpaceContext)

  const [userData, setUserData] = useState({
    name: existingUser.name || "",
    email: existingUser.email || "",
    space_operator_id:
      existingUser.space_operator_id || user.space_operator_id || "",
    user_type: existingUser.user_type || "",
    assigned_location_ids: existingUser.assigned_location_ids || "",
  })

  const [availableLocations, setAvailableLocations] = useState([])

  const setValue = (name, value) => {
    if (
      name === "assigned_location_ids" &&
      userData.assigned_location_ids.length > 0
    ) {
      let existingValues = userData.assigned_location_ids.split(",")
      if (existingValues.indexOf(value) > -1) {
        delete existingValues[existingValues.indexOf(value)]
        setUserData({ ...userData, [name]: existingValues.join(",") })
      } else {
        setUserData({
          ...userData,
          [name]: [...existingValues, value].join(","),
        })
      }
    } else {
      setUserData({ ...userData, [name]: value })
    }
  }

  const {
    data: { locationsCategorizedBySpaceOperator },
  } = useContext(SpaceContext)

  useEffect(() => {
    const selectedSpaceOperator = locationsCategorizedBySpaceOperator.find(
      (so) => so.id === userData.space_operator_id
    )
    if (selectedSpaceOperator && selectedSpaceOperator.spaces) {
      setAvailableLocations(selectedSpaceOperator.spaces)
    }
  }, [userData.space_operator_id])

  const handleSubmit = () => {
    const url_slug = buttonLabel === "Add" ? "add" : "update"
    const { name, email, space_operator_id, user_type, assigned_location_ids } =
      userData
    if (!name || !email || !space_operator_id) return
    makeRequest(`users/${url_slug}`, {
      id: existingUser && existingUser.id,
      name,
      user_type,
      email,
      space_operator_id,
      assigned_location_ids:
        user_type === SO_USER ? assigned_location_ids : null,
    }).then((res) => {
      if (res.user) {
        // success. Display the message and go to space-operators
        toast.success(
          `User was ${
            buttonLabel === "Add" ? "created" : "updated"
          } successfully.`
        )
      } else {
        if (res.user && res.user.detail) {
          toast.error("Failed: " + res.user.detail)
        }
      }
    })
  }

  const invalidLocations =
    userData.user_type === SO_ADMIN
      ? false
      : userData.assigned_location_ids === ""

  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label>
              User's Name
              {!userData.name && userData.contact_name
                ? ` (Contact name: ${userData.contact_name})`
                : ""}
            </Label>
            <Input
              type="text"
              disabled={existingUser && existingUser.name}
              placeholder="Full Name"
              value={userData.name}
              onChange={(e) => setValue("name", e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>User's Email</Label>
            <Input
              type="email"
              placeholder="Email"
              disabled={existingUser && existingUser.email}
              value={userData.email}
              onChange={(e) => setValue("email", e.target.value)}
            />
          </FormGroup>
          <OnlyAuthorized permission={"create:space-operators"}>
            <FormGroup>
              <Label>Space Operator</Label>
              <Input
                type="select"
                placeholder="Email"
                disabled={
                  existingUser &&
                  existingUser.space_operator_id &&
                  existingUser.email !== "ws.space.user@gmail.com"
                }
                value={userData.space_operator_id}
                onChange={(e) => setValue("space_operator_id", e.target.value)}
              >
                <option value="" disabled>
                  Please select
                </option>
                {locationsCategorizedBySpaceOperator.map((sp) => (
                  <option value={sp.id} key={sp.id}>
                    {sp.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </OnlyAuthorized>

          <FormGroup>
            <Label>User type</Label>
            {userTypes.map(({ label, value }) => (
              <FormGroup check key={value}>
                <Label>
                  <Input
                    type="radio"
                    name="userRole"
                    value={value}
                    checked={userData.user_type === value}
                    onChange={(e) => setValue("user_type", value)}
                  />{" "}
                  {label}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
          {userData.space_operator_id && availableLocations.length === 0 && (
            <p>
              This space operator doesn't have any locations. Please add at
              least one location before adding users.
            </p>
          )}
          {availableLocations.length > 0 && userData.user_type === SO_USER && (
            <FormGroup>
              <Label>Please select the locations allocated to this user.</Label>
              {availableLocations.map(({ id, space_name }) => (
                <FormGroup check key={id}>
                  <Label>
                    <Input
                      type="checkbox"
                      name="allocatedSpaces"
                      value={id}
                      checked={
                        userData.assigned_location_ids.split(",").indexOf(id) >
                        -1
                      }
                      onChange={(e) => setValue("assigned_location_ids", id)}
                    />{" "}
                    {space_name}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
          )}
          <FormGroup>
            <Button
              color={"success"}
              onClick={handleSubmit}
              disabled={
                availableLocations.length === 0 ||
                !userData.name ||
                !userData.email ||
                !userData.user_type ||
                invalidLocations
              }
            >
              {buttonLabel}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export const userTypes = [
  {
    label: "Administrator",
    value: SO_ADMIN,
  },
  {
    label: "Manager",
    value: SO_USER,
  },
]

export default UserForm
