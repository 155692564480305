import React, { useContext } from "react"
import { Link } from "gatsby"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { SpaceContext } from "@hooks/SpaceContext"
import { Col, Row } from "reactstrap"
import { userTypes } from "./_user_form"

const UsersPage = () => {
  const {
    data: { users },
  } = useContext(SpaceContext)

  return (
    <>
      <SEO title="Users" />
      <Layout title={"Users"} progress={users.length === 0}>
        <Row>
          <Col lg={"12"} className={"text-right mb-3"}>
            <Link to={"/users/add"} className={"btn btn-success btn-sm"}>
              Add new user
            </Link>
          </Col>
          <Col lg="12">
            <UsersTable />
          </Col>
        </Row>
      </Layout>
    </>
  )
}

const UsersTable = () => {
  const {
    data: { user, locationsCategorizedBySpaceOperator, users },
  } = useContext(SpaceContext)

  return (
    <div className="table-responsive">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th style={{ width: "45%" }}>Name</th>
            <th style={{ width: "20%" }} className={"text-center"}>
              Email
            </th>
            <th style={{ width: "20%" }} className={"text-center"}>
              Permissions
            </th>
            <th style={{ width: "15%" }} className={"text-center"} />
          </tr>
        </thead>
        <tbody>
          {users.map((u) => {
            if (u.id === user.id) return
            return (
              <tr key={u.id}>
                <td>{u.name}</td>
                <td className={"text-center"}>{u.email}</td>
                <td className={"text-center"}>
                  {userTypes.find((type) => type.value === u.user_type).label}
                </td>
                <td className={"text-center"}>
                  <Link to={`/users/edit?user_id=${u.id}`}>Edit</Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default UsersPage
